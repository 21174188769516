import { cn } from '@/lib/utils'
import { type FieldMetadata, useInputControl } from '@conform-to/react'
import type { SelectProps } from '@radix-ui/react-select'
import { SelectContent } from '@radix-ui/react-select'
import type React from 'react'
import { useId } from 'react'
import type { TextareaAutosizeProps } from 'react-textarea-autosize'
import { Button } from './ui/button'
import { Checkbox, type CheckboxProps } from './ui/checkbox'
import { Input } from './ui/input'
import { Label } from './ui/label'
import { Select, SelectItem, SelectTrigger, SelectValue } from './ui/select'
import { Switch } from './ui/switch'
import { Textarea } from './ui/textarea'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
}: {
  errors?: ListOfErrors
  id?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className='flex flex-col gap-1 pt-1'>
      {errorsToRender.map((e) => (
        <li key={e} className='text-xs font-bold text-destructive'>
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label
        htmlFor={id}
        {...labelProps}
        className={cn(
          'pb-2 block text-secondary-foreground text-xs font-medium leading-tight',
          labelProps.className,
        )}
      />

      <Input
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...inputProps}
      />

      <div className='min-h-7'>
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: TextareaAutosizeProps
  errors?: ListOfErrors
  className?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <Label
        {...labelProps}
        htmlFor={id}
        className={cn(
          'pb-2 block text-secondary-foreground text-xs font-medium leading-tight',
          labelProps.className,
        )}
      />
      <Textarea
        id={id}
        aria-invalid={errorId ? true : undefined}
        aria-describedby={errorId}
        {...textareaProps}
        rows={textareaProps.rows ?? 3}
      />
      <div className='min-h-7'>
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps
  const fallbackId = useId()
  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })
  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className='flex items-center gap-2'>
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={(event) => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={(event) => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type='button'
        />
        <label
          htmlFor={id}
          {...labelProps}
          className='self-center text-sm text-muted-foreground'
        />
      </div>

      <div className='min-h-7'>
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export const SelectField = ({
  meta,
  items,
  placeholder,
  className,
  ...props
}: {
  meta: FieldMetadata<string>
  items: Array<{ name: string; value: string }>
  className?: string
  placeholder: string
} & SelectProps) => {
  const control = useInputControl(meta)

  return (
    <div className={className}>
      <Select
        {...props}
        value={control.value ?? ''}
        onValueChange={control.change}
        onOpenChange={(open) => {
          if (!open) {
            control.blur()
          }
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {items.map((item) => {
            return (
              <SelectItem key={item.value} value={item.value}>
                {item.name}
              </SelectItem>
            )
          })}
        </SelectContent>
      </Select>
    </div>
  )
}

export function SwitchConform({
  meta,
}: {
  meta: FieldMetadata<boolean>
}) {
  const control = useInputControl(meta)

  return (
    <Switch
      name={meta.name}
      checked={control.value === 'on'}
      onCheckedChange={(checked) => {
        control.change(checked ? 'on' : '')
      }}
      onFocus={control.focus}
      onBlur={control.blur}
    />
  )
}

export function ButtonSwitchConform({
  meta,
  buttonName,
  className,
  onClick,
}: {
  meta: FieldMetadata<string>
  buttonName: string
  className: string
  onClick?: () => void
}) {
  const control = useInputControl(meta)

  return (
    <Button
      type='button'
      variant='transparent'
      size='content'
      name={meta.name}
      onClick={() => {
        control.change('on')
        if (onClick && control.value !== 'on') {
          onClick()
        }
      }}
      onFocus={control.focus}
      onBlur={control.blur}
      className={className}
    >
      {buttonName}
    </Button>
  )
}
